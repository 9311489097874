import { FunctionComponent } from "react";
import styles from "./NINHOCOMINGSOONPAGE.module.css";
import React from "react";

const NINHOCOMINGSOONPAGE: FunctionComponent = () => {
  return (
    <div className={styles.ninhoComingSoonPage}>
      <div className={styles.frame}>
        <img className={styles.frameIcon} alt="" src="/frame@2x.png" />
      </div>
      <div className={styles.brevemente}>BREVEMENTE</div>

      <div className={styles.frame1}>
        <div className={styles.socialMedia}>
          <a href="https://www.facebook.com/ninhocarwash/">
            <img className={styles.vectorIcon} alt="Facebook Link" src="/vector.svg" />
          </a>
          <a href="https://www.instagram.com/ninho_car_wash/">
            <img className={styles.vectorIcon1} alt="Instagram Link" src="/vector1.svg" />
          </a>
          <a href="https://api.whatsapp.com/send?phone=244951670781">
          <img className={styles.vectorIcon} alt="Whatsapp Link" src="/vector2.svg" />
          </a>
        </div>
      </div>
      <div
        className={styles.todosOsDireitos}
      >{`Todos os direitos reservados © 2021 Ninho Group `}</div>
    </div>
  );
};

export default NINHOCOMINGSOONPAGE;
